import { useMutation, useQuery } from '@apollo/client'
import { Box, Chip, CircularProgress, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageMetaTags from '../../base/PageMetaTags'
import Layout from '../../template/Layout'
import ProfileIcon from '../../assets/icons/profile.svg'
import WebsiteIcon from '../../assets/icons/website.svg'
import EDITICON from '../../assets/icons/EDIT.svg'
import DeleteIcon from '../../assets/icons/DELETE.svg'
import DeleteEntityBox from '../../components/DeleteEntityBox'
import DepartmentIcon from '../../assets/icons/DEPARTMENT.svg'
import LocationIcon from '../../assets/icons/LOCATION.svg'
import ExperienceIcon from '../../assets/icons/EXPERIENCE.svg'
import IndustryIcon from '../../assets/icons/INDUSTRY.svg'
import variables from '../../settings/_variables.scss'
import { StyledIcons, StyledBenefitsChips, StyledIconBox } from '../../base/commonStyles'
import { Button } from '../../components'
import { useSelector } from 'react-redux'
import UserRestriction from '../../components/UserRestriction'
import SuggestedJobsSideSection from '../../components/SuggestedJobsSideSection'
import Loader from '../../components/Loader'
import {
    APPLY_JOB_MUTATION,
    CANDIDATE_JOB_DETAILS_QUERY,
    DELETE_JOB_MUTATION,
    INVITE_RESPONSE_MUTATION,
    JOB_DETAILS_QUERY
} from '../../queries/jobPostQueries'
import {
    addHttpsToUrl,
    getCompanyIndustries,
    getEmployeesLabel,
    getExperienceLabel,
    getStringFromArray,
    otherBenefitsChips,
    urlSlug
} from '../../utils/commonFunctions'
import Icons from '../../components/Icons'
import MoreJobsSideSection from '../../components/MoreJobsSideSection'
import StatusBox from '../../components/StatusBox'
import BuntonRecommendations from '../../components/BuntonRecommendations'
import JobTypeIcon from '../../assets/icons/job-type-icon.svg'
import ReactHtmlParser from 'react-html-parser'
import ShareJobButton from '../../components/elements/ShareJobButton'
import ShareModal from '../../components/organisams/ShareModal'
import ThankyouModal from '../../components/organisams/ThankyouModal'
import { useTranslation } from 'react-i18next'
import IntrestingJobs from '../../components/IntrestingJobs'
import DefaultCompanyLogo from '../../components/molecules/DefaultCompanyLogo'
import LikeButton from '../../components/molecules/LikeButton'
import ApplyJobButton from '../../components/molecules/ApplyJobButton'

const JobDetails = props => {
    const { t } = useTranslation()
    const [deleteJob, setDeleteJobModal] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [thanksModal, setThanksModal] = useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const params = useParams()
    const { isUserAuthenticated, loginDetails, loginCheckAuthDetails } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const navigate = useNavigate()
    const [isSuccess, setIsSuccess] = useState(false)
    const [isApplied, setIsApplied] = useState(false)
    const [applyLoading, setApplyLoading] = useState(false)
    const [declineLoader, setDeclineLoader] = useState(false)
    const [response, setResponse] = useState(null)

    const [applyJobMutation] = useMutation(APPLY_JOB_MUTATION, {
        onCompleted(data) {
            setSuccessMessage('Applied!')
            setApplyLoading(false)
            setIsSuccess(true)
            setIsApplied(true)
            setTimeout(() => {
                setIsSuccess(false)
            }, 3000)
        }
    })

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }

    const handleApply = () => {
        setApplyLoading(true)
        applyJobMutation({
            variables: {
                jobId: params.id,
                userId: loginCheckAuthDetails.id ? loginCheckAuthDetails.id : loginDetails.id
            }
        })
    }
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const [deleteMutation, { loading: deleteLoading, data: deleteData, error: deleteError }] =
        useMutation(DELETE_JOB_MUTATION, {
            onCompleted(data) {
                setDeleteJobModal(false)
                setSuccessMessage('Job Deleted Successfully!')
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                }, 5000)

                navigate(userRole === 'admin' ? '/admin-portal/job-list' : '/job-list')
            },
            onError(error) {
                console.log(error, 'error')
            }
        })

    const { loading, data, refetch } = useQuery(
        userRole === 'user' ? CANDIDATE_JOB_DETAILS_QUERY : JOB_DETAILS_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                id: params.id
            },
            onError(err) {
                let redirect_url = '/job-list'
                if (userRole === 'admin') {
                    redirect_url = '/admin-portal/job-list'
                }
                navigate(redirect_url)
            }
        }
    )

    const [
        responseMutation,
        { loading: invitationLoader, data: invitationData, error: invitationError }
    ] = useMutation(INVITE_RESPONSE_MUTATION, {
        onCompleted(data) {
            setSuccessMessage(
                response === 'accept'
                    ? 'Invitation Accepted'
                    : response === 'decline'
                    ? 'Invitation Declined'
                    : ''
            )
            setIsSuccess(true)
            setApplyLoading(false)
            setDeclineLoader(false)
            setTimeout(() => {
                setIsSuccess(false)
                refetch()
            }, 3000)
        },
        onError(error) {
            console.log(error, 'error')
        }
    })

    if (loading) return <Loader />
    const jobDetails = data.jobPost
    const jobBenefits = jobDetails.benefits.length > 0 ? jobDetails.benefits : {}
    return (
        <Layout>
            <PageMetaTags title='Job Details | Bunton' />

            <Container
                maxWidth='xl'
                sx={{
                    mt: {
                        xs: 3,
                        sm: 5,
                        md: 10
                    }
                }}
            >
                <Grid
                    container
                    rowSpacing={{ xs: 5, md: 0 }}
                    columnSpacing={{ xs: 0, sm: 4 }}
                    sx={{ mb: 4 }}
                >
                    <Grid item xl={9} lg={9} md={9} sm={12} xs={12} sx={{ pt: '10px !important' }}>
                        <Box
                            sx={{
                                backgroundColor: variables.CYAN2,
                                borderRadius: '50px',
                                p: 3
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xl={1.5} lg={1.5} md={2} sm={2.5} xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box>
                                            <DefaultCompanyLogo
                                                imgSrc={jobDetails.companyLogoUrl}
                                                sx={{
                                                    height: {
                                                        xs: '50px',
                                                        sm: '70px',
                                                        lg: '88px'
                                                    },
                                                    width: {
                                                        xs: '50px',
                                                        sm: '70px',
                                                        lg: '88px'
                                                    }
                                                }}
                                            />
                                            {responsiveBreakpoint !== 'mob' &&
                                                jobDetails.status === 'draft' && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {' '}
                                                        <Chip
                                                            variant='status-chip'
                                                            label={jobDetails.status.toUpperCase()}
                                                            sx={{ my: 1 }}
                                                        />
                                                    </Box>
                                                )}
                                        </Box>
                                        {responsiveBreakpoint === 'mob' ? (
                                            <Box sx={{ ml: 2 }}>
                                                <Box>
                                                    {jobDetails.companyId ? (
                                                        <Link
                                                            to={`/company-details/${urlSlug(
                                                                jobDetails.companyName
                                                            )}/${jobDetails.companyId}`}
                                                            style={{
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='h5'
                                                                className='small-size-strong'
                                                            >
                                                                {jobDetails.companyName}
                                                            </Typography>
                                                        </Link>
                                                    ) : (
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size-strong'
                                                        >
                                                            {jobDetails.companyName}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                {jobDetails.company.noOfEmployees !== null ? (
                                                    <Box
                                                        sx={{
                                                            my: 1,
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={ProfileIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {jobDetails.company.noOfEmployees
                                                                ? getEmployeesLabel(
                                                                      jobDetails.company
                                                                          .noOfEmployees
                                                                  )
                                                                : '---'}
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                                {jobDetails.company.companyWebsite !== null ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={WebsiteIcon} />

                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            <Box
                                                                component='a'
                                                                href={addHttpsToUrl(
                                                                    jobDetails.company
                                                                        .companyWebsite
                                                                )}
                                                                target='_blank'
                                                            >
                                                                {jobDetails.company.companyWebsite
                                                                    ? jobDetails.company
                                                                          .companyWebsite
                                                                    : '---'}
                                                            </Box>
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Grid>
                                <Grid item xl={10.5} lg={10.5} md={10} sm={9.5} xs={12}>
                                    <Grid container spacing={{ xs: 2, md: 1 }}>
                                        {responsiveBreakpoint !== 'mob' ? (
                                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Box>
                                                    {jobDetails.companyId ? (
                                                        <Link
                                                            to={`/company-details/${urlSlug(
                                                                jobDetails.companyName
                                                            )}/${jobDetails.companyId}`}
                                                            style={{
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='h5'
                                                                className='small-size-strong'
                                                            >
                                                                {jobDetails.companyName}
                                                            </Typography>
                                                        </Link>
                                                    ) : (
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size-strong'
                                                        >
                                                            {jobDetails.companyName}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                {jobDetails.company.noOfEmployees !== null ? (
                                                    <Box
                                                        sx={{
                                                            my: 1,
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={ProfileIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {jobDetails.company.noOfEmployees
                                                                ? getEmployeesLabel(
                                                                      jobDetails.company
                                                                          .noOfEmployees
                                                                  )
                                                                : '---'}
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                                {jobDetails.company.companyWebsite !== null ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={WebsiteIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            <Box
                                                                component='a'
                                                                href={addHttpsToUrl(
                                                                    jobDetails.company
                                                                        .companyWebsite
                                                                )}
                                                                target='_blank'
                                                            >
                                                                {jobDetails.company.companyWebsite
                                                                    ? jobDetails.company
                                                                          .companyWebsite
                                                                    : '---'}
                                                            </Box>
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                            </Grid>
                                        ) : null}

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                            sx={{
                                                justifyContent: {
                                                    xs: 'center',
                                                    md: 'right'
                                                },
                                                display: {
                                                    xs:
                                                        userRole === 'user' &&
                                                        jobDetails.invitedToApply
                                                            ? 'block'
                                                            : 'flex',
                                                    md: 'block'
                                                }
                                            }}
                                        >
                                            {isUserAuthenticated ? (
                                                <Box>
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: {
                                                                    xs: 'center',
                                                                    md: 'right'
                                                                },
                                                                mb: 2
                                                            }}
                                                        >
                                                            {userRole === 'user' ? (
                                                                <Box alignItems='center' mr={1}>
                                                                    <ShareJobButton
                                                                        textEnable={false}
                                                                        onClick={() => {
                                                                            setShowShareModal(true)
                                                                        }}
                                                                        iconEnable={true}
                                                                        sx={{
                                                                            width: '47px',
                                                                            height: '47px',
                                                                            minWidth: '47px',
                                                                            p: '11px'
                                                                        }}
                                                                        iconStyle={{
                                                                            ml: 0,
                                                                            height: '25px',
                                                                            width: '25px'
                                                                        }}
                                                                    />
                                                                    <ShareModal
                                                                        open={showShareModal}
                                                                        handleClose={() =>
                                                                            setShowShareModal(false)
                                                                        }
                                                                        jobDetails={jobDetails}
                                                                        shareJob={true}
                                                                        userId={userId}
                                                                        showThankYou={() => {
                                                                            setThanksModal(true)
                                                                            setTimeout(() => {
                                                                                setThanksModal(
                                                                                    false
                                                                                )
                                                                            }, 3000)
                                                                        }}
                                                                    />
                                                                    <ThankyouModal
                                                                        message='Thank you for sharing!'
                                                                        open={thanksModal}
                                                                        handleClose={() =>
                                                                            setThanksModal(false)
                                                                        }
                                                                    />
                                                                </Box>
                                                            ) : null}
                                                            <Box>
                                                                <Box alignItems='center' mr={1}>
                                                                    {jobDetails.status ===
                                                                        'published' && (
                                                                        <LikeButton
                                                                            jobPostId={
                                                                                jobDetails.id
                                                                            }
                                                                            userId={userId}
                                                                            role={userRole}
                                                                            isLiked={
                                                                                [
                                                                                    'admin',
                                                                                    'employer'
                                                                                ].includes(userRole)
                                                                                    ? true
                                                                                    : jobDetails.isLiked
                                                                            }
                                                                            isUserAuthenticated={
                                                                                isUserAuthenticated
                                                                            }
                                                                            totalLikes={
                                                                                jobDetails.likes
                                                                            }
                                                                            sx={{
                                                                                height: {
                                                                                    xs: '42px',
                                                                                    md: '47px'
                                                                                },
                                                                                width: {
                                                                                    xs: '42px',
                                                                                    md: '47px'
                                                                                },
                                                                                minWidth: '80px'
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                            {(userRole === 'employer' &&
                                                                Number(userId) ===
                                                                    jobDetails.companyUserId) ||
                                                            userRole === 'admin' ? (
                                                                <Box sx={{ display: 'flex' }}>
                                                                    <Link
                                                                        to={
                                                                            userRole === 'admin'
                                                                                ? '/admin-portal/edit-job/' +
                                                                                  jobDetails.id
                                                                                : '/edit-job/' +
                                                                                  jobDetails.id
                                                                        }
                                                                    >
                                                                        <StyledIconBox
                                                                            sx={{
                                                                                background: '#fff',
                                                                                marginLeft: '15px'
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    position:
                                                                                        'relative',
                                                                                    top: '5px',
                                                                                    color: variables.BLUE
                                                                                }}
                                                                            >
                                                                                <Icons
                                                                                    src={EDITICON}
                                                                                    sx={{
                                                                                        height: '30px',
                                                                                        width: '30px'
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </StyledIconBox>
                                                                    </Link>
                                                                    <Link>
                                                                        <StyledIconBox
                                                                            sx={{
                                                                                background: `${variables.ORANGE}`,
                                                                                marginLeft: '15px'
                                                                            }}
                                                                            onClick={() => {
                                                                                setDeleteJobModal(
                                                                                    true
                                                                                )
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    position:
                                                                                        'relative',
                                                                                    top: '25%',
                                                                                    color: '#ffffff'
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    component='img'
                                                                                    src={DeleteIcon}
                                                                                    sx={{
                                                                                        height: '20px'
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </StyledIconBox>
                                                                    </Link>
                                                                </Box>
                                                            ) : null}
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        {jobDetails.externalLink &&
                                                        userRole === 'user' ? (
                                                            jobDetails.externalLink ? (
                                                                <Button
                                                                    label={
                                                                        <Typography
                                                                            sx={{
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            View on
                                                                            <br />
                                                                            Company website
                                                                        </Typography>
                                                                    }
                                                                    target='_blank'
                                                                    color='secondary'
                                                                    variant='contained'
                                                                    size='large'
                                                                    sx={{
                                                                        padding: '8px 30px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '900',
                                                                        cursor: 'hand',
                                                                        width: {
                                                                            xs: '100%'
                                                                        },
                                                                        maxWidth: '212px'
                                                                    }}
                                                                    href={
                                                                        jobDetails.externalLink.indexOf(
                                                                            'http://'
                                                                        ) === 0 ||
                                                                        jobDetails.externalLink.indexOf(
                                                                            'https://'
                                                                        ) === 0
                                                                            ? jobDetails.externalLink
                                                                            : `//${jobDetails.externalLink}`
                                                                    }
                                                                >
                                                                    View on Company website
                                                                </Button>
                                                            ) : null
                                                        ) : userRole === 'user' ? (
                                                            <Box>
                                                                {jobDetails.invitedToApply &&
                                                                (!jobDetails.candidateApplied ||
                                                                    !isApplied) ? (
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            justifyContent: {
                                                                                xs: 'center',
                                                                                md: 'right'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            label={
                                                                                applyLoading ? (
                                                                                    <CircularProgress
                                                                                        size={20}
                                                                                        sx={{
                                                                                            color: variables.BLUE
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    t(
                                                                                        'buttons.accept'
                                                                                    )
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                applyLoading ||
                                                                                declineLoader
                                                                            }
                                                                            color='secondary'
                                                                            variant='inviteButtons'
                                                                            size='large'
                                                                            sx={{
                                                                                padding:
                                                                                    '10px 30px',
                                                                                fontWeight: '600',
                                                                                color: variables.GREEN,
                                                                                textTransform:
                                                                                    'none',
                                                                                mr: 1
                                                                            }}
                                                                            onClick={handleApply}
                                                                        />
                                                                        <Button
                                                                            label={
                                                                                declineLoader ? (
                                                                                    <CircularProgress
                                                                                        size={20}
                                                                                        sx={{
                                                                                            color: variables.BLUE
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    t(
                                                                                        'buttons.decline'
                                                                                    )
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                applyLoading ||
                                                                                declineLoader
                                                                            }
                                                                            color='secondary'
                                                                            variant='inviteButtons'
                                                                            size='large'
                                                                            sx={{
                                                                                ml: 1,
                                                                                fontWeight: '600',
                                                                                padding:
                                                                                    '10px 30px',
                                                                                color: variables.SOFTORANGE,
                                                                                textTransform:
                                                                                    'none'
                                                                            }}
                                                                            onClick={() => {
                                                                                setDeclineLoader(
                                                                                    true
                                                                                )
                                                                                setResponse(
                                                                                    'decline'
                                                                                )
                                                                                responseMutation({
                                                                                    variables: {
                                                                                        jobId: jobDetails.id,
                                                                                        response:
                                                                                            'decline'
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <ApplyJobButton
                                                                        jobId={params.id}
                                                                        candidateId={
                                                                            loginCheckAuthDetails.id
                                                                                ? loginCheckAuthDetails.id
                                                                                : loginDetails.id
                                                                        }
                                                                        hasCandidateApplied={
                                                                            jobDetails.candidateApplied ||
                                                                            isApplied
                                                                        }
                                                                    />
                                                                )}
                                                            </Box>
                                                        ) : (userRole === 'employer' &&
                                                              Number(userId) ===
                                                                  jobDetails.companyUserId) ||
                                                          userRole === 'admin' ? (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    textAlign: 'right',
                                                                    float: 'right'
                                                                }}
                                                            >
                                                                <Box>
                                                                    {jobDetails.noOfCandidatesApplied >
                                                                        0 && (
                                                                        <Button
                                                                            label={`${jobDetails.noOfCandidatesApplied} Applied`}
                                                                            color='secondary'
                                                                            variant='contained'
                                                                            size='large'
                                                                            sx={{
                                                                                padding: '8px 30px',
                                                                                minWidth: '150px'
                                                                            }}
                                                                            component={Link}
                                                                            to={
                                                                                '/applied-candidate/' +
                                                                                jobDetails.id
                                                                            }
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        ) : null}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        textAlign: {
                                                            xs: 'center',
                                                            md: 'right'
                                                        }
                                                    }}
                                                >
                                                    <Box alignItems='center' mr={1}>
                                                        <LikeButton
                                                            jobPostId={jobDetails.id}
                                                            userId={userId}
                                                            role={userRole}
                                                            isLiked={
                                                                ['admin', 'employer'].includes(
                                                                    userRole
                                                                )
                                                                    ? true
                                                                    : jobDetails.isLiked
                                                            }
                                                            isUserAuthenticated={
                                                                isUserAuthenticated
                                                            }
                                                            totalLikes={jobDetails.likes}
                                                            sx={{
                                                                height: {
                                                                    xs: '42px',
                                                                    md: '47px'
                                                                },
                                                                width: {
                                                                    xs: '42px',
                                                                    md: '47px'
                                                                },
                                                                minWidth: '80px'
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Box sx={{ my: 3 }}>
                                            <Typography variant='h2' className='modal-heading'>
                                                {jobDetails.jobTitle}
                                            </Typography>
                                            {responsiveBreakpoint === 'mob' &&
                                                jobDetails.status === 'draft' && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            justifyContent: 'left'
                                                        }}
                                                    >
                                                        {' '}
                                                        <Chip
                                                            variant='status-chip'
                                                            label={jobDetails.status.toUpperCase()}
                                                            sx={{ my: 1 }}
                                                        />
                                                    </Box>
                                                )}
                                        </Box>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ mb: 5 }}
                                            alignItems='center'
                                        >
                                            {jobDetails.departments && jobDetails.departments.length > 0 ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={DepartmentIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {getStringFromArray(
                                                                jobDetails.departments,
                                                                jobDetails.otherDepartment
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : null}
                                            {jobDetails.experienceRequired && jobDetails.experienceRequired !== null ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={ExperienceIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {getExperienceLabel(
                                                                jobDetails.experienceRequired
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : null}

                                            {jobDetails.industries && jobDetails.industries.length > 0 ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={IndustryIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {getCompanyIndustries(
                                                                jobDetails.industries,
                                                                jobDetails.otherIndustry
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : null}
                                            {jobDetails.states && jobDetails.states.length > 0 ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={LocationIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {getStringFromArray(jobDetails.states)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : null}
                                            {jobDetails.jobTypes && jobDetails.jobTypes.length > 0 ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <StyledIcons src={JobTypeIcon} />
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size'
                                                        >
                                                            {getStringFromArray(
                                                                jobDetails.jobTypes
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Box sx={{ mb: 6 }}>
                                            <Box>
                                                <Typography
                                                    variant='h2'
                                                    className='modal-heading'
                                                    sx={{ fontSize: '22px', mb: '20px' }}
                                                >
                                                    Job description
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: variables.BLUE,
                                                        overflowWrap: 'break-word'
                                                    }}
                                                >
                                                    {ReactHtmlParser(jobDetails.jobDescription)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {jobBenefits.length > 0 ? (
                                            <Box>
                                                <Typography
                                                    variant='h2'
                                                    className='modal-heading'
                                                    sx={{ fontSize: '22px', mb: '10px' }}
                                                >
                                                    Benefits
                                                </Typography>
                                                <Box>
                                                    {jobBenefits.map(benefit =>
                                                        benefit.name !== 'Others' ? (
                                                            <StyledBenefitsChips
                                                                label={benefit.name}
                                                                variant='outlined'
                                                                sx={{
                                                                    fontSize: '15px',
                                                                    fontWeight: '400'
                                                                }}
                                                            />
                                                        ) : benefit.name === 'Others' &&
                                                          jobDetails.otherBenefits &&
                                                          jobDetails.otherBenefits !== '' ? (
                                                            otherBenefitsChips(
                                                                jobDetails.otherBenefits
                                                            )
                                                        ) : null
                                                    )}
                                                </Box>
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {(isUserAuthenticated && responsiveBreakpoint !== 'mob') ||
                    (!isUserAuthenticated && responsiveBreakpoint !== 'mob') ? (
                        <>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                {isUserAuthenticated ? (
                                    userRole === 'user' ? (
                                        <SuggestedJobsSideSection jobDetails={true} />
                                    ) : userRole === 'employer' ? (
                                        <BuntonRecommendations />
                                    ) : userRole === 'admin' ? null : (
                                        <MoreJobsSideSection />
                                    )
                                ) : (
                                    <MoreJobsSideSection />
                                )}
                            </Grid>
                        </>
                    ) : null}
                </Grid>
                {isUserAuthenticated ? (
                    !['mob', 'tab'].includes(responsiveBreakpoint) && userRole === 'user' ? (
                        <Box sx={{ my: 8 }}>
                            <IntrestingJobs />
                        </Box>
                    ) : null
                ) : (
                    <UserRestriction />
                )}
            </Container>
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
            <DeleteEntityBox
                open={deleteJob}
                deleteMutation={deleteMutation}
                entityId={jobDetails.id}
                entityStatus={jobDetails.status}
                deleteLoading={deleteLoading}
                closeDelete={handleDeleteClose}
            />
        </Layout>
    )
}

export default JobDetails
