import PLUS from '../../src/assets/icons/PLUS.svg'
import AVATARICON from '../../src/assets/icons/AVATAR.svg'
import { keys, TextStrings } from './strings'

export const DateDefaultFromat = "DD-MM-YYYY"

export const surveyLink = '#'

export const LANGUAGES = [
    {
        name: 'English'
    },
    {
        name: 'Mandarin Chinese'
    },
    {
        name: 'Hindi'
    },
    {
        name: 'German'
    },
    {
        name: 'Spanish'
    },
    {
        name: 'French'
    },
    {
        name: 'Arabic'
    },
    {
        name: 'Bengali'
    },
    {
        name: 'Russian'
    }
]

export const AVATAR = [
    {
        name: 'AVATAR_ONE'
    },
    {
        name: 'AVATAR_TWO'
    },
    {
        name: 'AVATAR_THREE'
    },
    {
        name: 'AVATAR_FOUR'
    },
    {
        name: 'AVATAR_FIVE'
    },
    {
        name: 'AVATAR_SIX'
    }
    // {
    //     name: 'AVATAR_SEVEN'
    // },
    // {
    //     name: 'AVATAR_EIGHT'
    // },
    // {
    //     name: 'AVATAR_NINE'
    // },
    // {
    //     name: 'AVATAR_TEN'
    // }
]

export const BUTTON_DATA = [
    { icon: PLUS, label: TextStrings.education, key: keys.education },
    { icon: PLUS, label: TextStrings.workExperience, key: keys.workExperience },
    { icon: PLUS, label: TextStrings.uploadResume, key: keys.uploadResume },
    { icon: PLUS, label: TextStrings.languages, key: keys.languages },
    { icon: AVATARICON, label: TextStrings.selectAnAvatar, key: keys.selectAnAvatar },
    { icon: PLUS, label: TextStrings.additionalInfos, key: keys.additionalInfos }
]

export const PASSWORDMESSAGE = <>Invalid Password Format.</>
