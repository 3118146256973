import { gql, useQuery } from '@apollo/client'
import { Box, Container, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import Loader from '../../../components/Loader'
import variables from '../../../settings/_variables.scss'
import { Link } from 'react-router-dom'
import NotificationBadge from '../../../components/elements/NotificationBadge'

const COMPANY_QUERY = gql`
    query DashboardData {
        companiesCount
        getJobCounts
        candidatesCount
        getContactUsDataCount
        getAdminNotificationCount
    }
`

const CustomDataBox = styled(Box)`
    height: 120px;
    max-height: 120px;
    background-color: ${variables.CYAN1};
    border-radius: 20px;
    padding: 16px;
    height: fit-content;
    margin: auto;
`

const AdminDashboard = props => {
    const { loading, error, data, fetchMore, refetch } = useQuery(COMPANY_QUERY, {
        fetchPolicy: 'network-only'
    })
    if (loading) return <Loader />
    const companiesCount = data.companiesCount
    const jobCount = data.getJobCounts
    const userCounts = data.candidatesCount
    const contactRequest = data.getContactUsDataCount
    const adminNotifications = data.getAdminNotificationCount
    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2} sx={{ mb: '30px' }} alignItems='center'>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                            <Link to='/admin-portal/companies'>
                                <CustomDataBox>
                                    <Typography
                                        variant='h3'
                                        className='strong'
                                        sx={{ textAlign: 'center' }}
                                    >
                                        Total Companies
                                    </Typography>

                                    <Typography
                                        component='h2'
                                        sx={{ fontSize: '50px', textAlign: 'center' }}
                                    >
                                        {companiesCount}
                                    </Typography>
                                </CustomDataBox>
                            </Link>
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                            <Link to='/admin-portal/users'>
                                <CustomDataBox>
                                    <NotificationBadge
                                        showBadge={
                                            adminNotifications &&
                                            adminNotifications.new_candidates > 0
                                                ? true
                                                : false
                                        }
                                        content={
                                            adminNotifications &&
                                            adminNotifications.new_candidates > 0
                                                ? adminNotifications.new_candidates
                                                : 0
                                        }
                                        badgeSx={{
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant='h3'
                                            className='strong'
                                            sx={{ textAlign: 'center' }}
                                        >
                                            Total Candidates
                                        </Typography>
                                    </NotificationBadge>

                                    <Typography
                                        component='h2'
                                        sx={{ fontSize: '50px', textAlign: 'center' }}
                                    >
                                        {userCounts}
                                    </Typography>
                                </CustomDataBox>
                            </Link>
                        </Grid>

                        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                            <Link to='/admin-portal/job-list'>
                                <CustomDataBox>
                                    <NotificationBadge
                                        showBadge={
                                            adminNotifications &&
                                            adminNotifications.new_job_posts > 0
                                                ? true
                                                : false
                                        }
                                        content={
                                            adminNotifications &&
                                            adminNotifications.new_job_posts > 0
                                                ? adminNotifications.new_job_posts
                                                : 0
                                        }
                                        badgeSx={{
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant='h3'
                                            className='strong'
                                            sx={{ textAlign: 'center' }}
                                        >
                                            Total Jobs
                                        </Typography>
                                    </NotificationBadge>

                                    <Typography
                                        component='h2'
                                        sx={{ fontSize: '50px', textAlign: 'center' }}
                                    >
                                        {jobCount}
                                    </Typography>
                                </CustomDataBox>
                            </Link>
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                            <Link to='/admin-portal/contact-request'>
                                <CustomDataBox>
                                    <NotificationBadge
                                        showBadge={
                                            adminNotifications &&
                                            adminNotifications.new_contact_request > 0
                                                ? true
                                                : false
                                        }
                                        content={
                                            adminNotifications &&
                                            adminNotifications.new_contact_request > 0
                                                ? adminNotifications.new_contact_request
                                                : 0
                                        }
                                        badgeSx={{
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant='h3'
                                            className='strong'
                                            sx={{ textAlign: 'center' }}
                                        >
                                            Contact Requests
                                        </Typography>
                                    </NotificationBadge>
                                    <Typography
                                        component='h2'
                                        sx={{ fontSize: '50px', textAlign: 'center' }}
                                    >
                                        {contactRequest}
                                    </Typography>
                                </CustomDataBox>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item md={8}> For further sections </Grid> */}
            </Grid>
        </Container>
    )
}

export default AdminDashboard
