import {
    Autocomplete,
    Box,
    Chip,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    MenuItem,
    Paper,
    styled,
    Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import variables from '../../../../settings/_variables.scss'
import { Button, TextInput } from '../../../../components'
import { StyleFormControl } from '../../../../base/commonStyles'
import { experiencesList } from '../../../../utils/constantData'
import { Controller, useForm } from 'react-hook-form'
import {
    getFormattedObjectForData,
    removeNoPreferenceEntry
} from '../../../../utils/commonFunctions'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorMessage, Regex, TextStrings } from '../../../../constants'
import Loader from '../../../../components/Loader'
import DownArrow from '../../../../assets/icons/ARROW DOWN.svg'
import Icons from '../../../../components/Icons'
import StatusBox from '../../../../components/StatusBox'
import WYSIWYGEditor from '../../../../components/WYSIWYGEditor'
import { stripHtml } from 'string-strip-html'
import {
    MANAGE_JOB_DETAILS_QUERY,
    MANAGE_JOB_POST_COMPANY_QUERY,
    MANAGE_JOB_POST_MUTATION
} from '../../../../queries/jobPostQueries'
import ControlledTextInput from '../../../../components/molecules/ControlledTextInput'
import ControlledCheckboxGroup from '../../../../components/molecules/ControlledCheckboxGroup'
import ControlledRadioGroup from '../../../../components/molecules/ControlledRadioGroup'
import CustomFormLabel from '../../../../components/elements/CustomFormLabel'
import SendJobForReviewModal from '../../../../components/organisams/SendJobForReviewModal'

const AddJobComponent = props => {
    let urlParams = useParams()
    const navigate = useNavigate()

    const [apiError, setApiError] = useState('')
    const [options, setOptions] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [isPublishing, setIsPublishing] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [sendForReviewModal, setSendForReviewModal] = useState(false)
    const [successMessage, setSuccessMessage] = useState(
        urlParams.jobId ? 'Job Details Updated Successfully!' : 'Job Added Successfully!'
    )
    const [jobStatus, setJobStatus] = useState(null)
    const [jobDescriptionState, setJobDescriptionState] = useState('')
    const { allIndustries, allStates, allDepartments, allBenefits, allJobTypes } = useSelector(
        state => state.initial
    )

    const { loginDetails, loginCheckAuthDetails } = useSelector(state => state.login)

    const isEditPage = urlParams.jobId ? true : false
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const location = useLocation()

    useEffect(() => {
        if (!isEditPage) {
            reset(initialFormState)
        }
    }, [isEditPage])

    let initialFormState = {
        companyId: '',
        jobTitle: '',
        experience: '',
        location: '',
        industry: '',
        otherIndustry: '',
        department: '',
        otherDepartment: '',
        jobDescription: '',
        externalLink: '',
        otherBenefits: '',
        jobTypes: '',
        benefits: []
    }

    const { loading: jobDetailLoading } = useQuery(MANAGE_JOB_DETAILS_QUERY, {
        fetchPolicy: 'network-only',
        skip: !isEditPage,
        variables: {
            id: urlParams.jobId
        },
        onCompleted(data) {
            refetch({ keyword: data.jobPost.company.companyName })
            let formData = {
                companyId: data.jobPost.company,
                jobTitle: data.jobPost.jobTitle,
                status: data.jobPost.status,
                experience: data.jobPost.experienceRequired,
                location: data.jobPost.states.map(item => {
                    return item.id
                }),
                industry: data.jobPost.industries.map(item => {
                    return item.id
                }),
                otherIndustry: data.jobPost.otherIndustry,
                otherBenefits: data.jobPost.otherBenefits,
                department: data.jobPost.departments.map(item => {
                    return item.id
                }),
                otherDepartment: data.jobPost.otherDepartment,
                jobDescription: data.jobPost.jobDescription,
                externalLink: data.jobPost.externalLink,
                jobTypes: data.jobPost.jobTypes
                    .map(item => {
                        return item.id
                    })
                    .join(),
                benefits: data.jobPost.benefits.map(item => item.id)
            }
            setJobDescriptionState(data.jobPost.jobDescription)
            setJobStatus(data.jobPost.status)
            reset(formData)
        }
    })

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
        watch,
        register,
        setFocus,
        setValue
    } = useForm({
        defaultValues: initialFormState
    })

    const watchJd = watch('jobDescription')
    React.useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
            return !!errors[field] ? field : a
        }, null)
        if (firstError) {
            setFocus(firstError)
        }
    }, [errors, setFocus])

    function handleMutliSelect(checkedName, fieldName) {
        const newNames = getValues(fieldName)?.includes(checkedName)
            ? getValues(fieldName)?.filter(name => name !== checkedName)
            : [...(getValues(fieldName) ?? []), checkedName]

        return newNames
    }

    const [manageJobPostMutation, { loading: saveLoading }] = useMutation(
        MANAGE_JOB_POST_MUTATION,
        {
            onCompleted(data) {
                let redirectionPath = ''
                setIsSaving(false)
                setIsPublishing(false)
                if(data && data.manageJobPost && data.manageJobPost.job) {
                    let message = ''
                    if(data.manageJobPost.job.status === 'draft') {
                        message  = 'Job saved as draft'
                        redirectionPath = '/job-details/' +  data.manageJobPost.job.id
                        
                    } else if( data.manageJobPost.job.status === 'published') {
                        message = 'Job Published Successfully!'
                        if (userRole !== 'admin') {
                            if (location.pathname.includes('edit-job-profile')) {
                                redirectionPath = '/my-profile'
                                
                            } else {
                                redirectionPath = '/job-list'
                            }
                        } else {
                            redirectionPath = '/admin-portal/job-list'
                        }
                    }
                    setSuccessMessage(message)
                    setIsSuccess(true)
                    setJobDescriptionState(data.manageJobPost.job.jobDescription)
                }
                setTimeout(() => {
                    setIsSuccess(false)
                    // reset(initialFormState)
                    navigate(redirectionPath)
                    document.getElementById('job_page-heading').scrollIntoView({
                        behavior: 'smooth'
                    });

                }, 3000)
            },
            onError(error) {
                setApiError(error)
            }
        }
    )

    const { loading, error, data, fetchMore, refetch } = useQuery(MANAGE_JOB_POST_COMPANY_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: '',
            showLatest: true
        },
        onCompleted(data) {
            setOptions(data.companies.edges.map(edge => edge.node))
        },
        onError(error) {}
    })

    const getFormattedFormData = (data) => {
        let formData = {}
        if (urlParams.jobId) {
            formData = {
                id: urlParams.jobId,
                companyId: props.companyId ? props.companyId : data.companyId.id,
                jobTitle: data.jobTitle,
                experienceRequired: data.experience,
                stateIds: data.location.join(),
                industryIds: data.industry.join(),
                otherIndustry: data.otherIndustry,
                departmentIds: data.department.join(),
                otherDepartment: data.otherDepartment,
                otherBenefits: data.otherBenefits,
                jobDescription: data.jobDescription,
                externalLink: data.externalLink,
                jobTypeIds: data.jobTypes,
                isActive: true,
                language: null,
                isCompanyHidden: false,
                benefitIds: data.benefits.join(),
                actionType: 'update'
            }
        } else {
            formData = {
                companyId: props.companyId ? props.companyId : data.companyId.id,
                jobTitle: data.jobTitle,
                experienceRequired: data.experience,
                stateIds: data.location.join(),
                industryIds: data.industry.join(),
                otherIndustry: data.otherIndustry,
                departmentIds: data.department.join(),
                otherDepartment: data.otherDepartment,
                otherBenefits: data.otherBenefits,
                jobDescription: data.jobDescription,
                externalLink: data.externalLink,
                jobTypeIds: data.jobTypes,
                isActive: true,
                language: null,
                isCompanyHidden: false,
                benefitIds: data.benefits.join(),
                actionType: 'create'
            }
        }
        return formData
    } 

    const formSubmit = data => {
        setApiError('')
        setIsPublishing(true)
        let formData = getFormattedFormData(data)
        manageJobPostMutation({
            variables: {
                ...formData,
                status: 'published'
            }
        })
    }

    const saveAsDraft = data => {
        setApiError('')
        setIsSaving(true)
        let formData = getFormattedFormData(data)
        manageJobPostMutation({
            variables: {
                ...formData,
                status: 'draft'
            }
        })
    }

    const onInputChange = (event, value, reason) => {
        if (value) {
            refetch({ keyword: value })
        } else {
            refetch({ keyword: value })
        }
    }

    const StyledMenuItems = styled(MenuItem)`
        padding: 6px 30px;
        text-align: right;
        font-size: 18px;
        color: ${variables.BLUE};
    `
    const [benefitData] = useState(getFormattedObjectForData(allBenefits))

    const getAdminLayoutForIndAndDept = () => {
        const industryLayout = (
            <Box {...register('industry')}>
                <CustomFormLabel
                    formLabel='Industry*'
                    formFieldError={(errors && errors.industry && errors.industry.message) || (errors && errors.otherIndustry && errors.otherIndustry.message)}
                />
                <ControlledCheckboxGroup
                    control={control}
                    register={register}
                    rules={{
                        validate: value => {
                            return value.length > 0 || ErrorMessage.emptyIndustries
                        }
                    }}
                    otherFieldRules={{
                        required: {
                            value: true,
                            message: ErrorMessage.emptyOthers
                        }
                    }}
                    name='industry'
                    options={removeNoPreferenceEntry(allIndustries)}
                    errors={errors && errors.industry && errors.industry.message}
                    otherFieldErrors={
                        errors && errors.otherIndustry && errors.otherIndustry.message
                    }
                    handleMutliSelect={handleMutliSelect}
                    resetField={() => setValue('otherIndustry')}
                />
            </Box>
        )

        const departmentLayout = (
            <Box {...register('department')}>
                <CustomFormLabel
                    formLabel='Department*'
                    formFieldError={(errors && errors.department && errors.department.message) || (errors && errors.otherDepartment && errors.otherDepartment.message)}
                />
                <ControlledCheckboxGroup
                    control={control}
                    register={register}
                    rules={{
                        validate: value => {
                            return value.length > 0 || ErrorMessage.emptyDepartment
                        }
                    }}
                    otherFieldRules={{
                        required: {
                            value: true,
                            message: ErrorMessage.emptyOthers
                        }
                    }}
                    name='department'
                    options={allDepartments}
                    errors={errors && errors.department && errors.department.message}
                    otherFieldErrors={
                        errors && errors.otherDepartment && errors.otherDepartment.message
                    }
                    handleMutliSelect={handleMutliSelect}
                    resetField={() => setValue('otherDepartment')}
                />
            </Box>
        )
        return (
            <Box>
                <Box>
                    {industryLayout}
                    {departmentLayout}
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Container
                maxWidth='xl'
                disableGutters
                sx={{
                    padding: {
                        xs: '0px 40px',
                        sm: '0px 60px',
                        md: '0px 146px'
                    },
                    maxWidth: {
                        xs: '100vw',
                        sm: '100vw',
                        md: '988px'
                    }
                }}
                id='job_page-heading'
            >
                { location && location.state && location.state.companyName ? (
                    <Box
                        sx={{
                            marginBottom: '73px'
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '30px',
                                fontWeight: '600',
                                color: variables.BLUE
                            }}
                        >
                            Add a job for {location.state.companyName}
                        </Typography>
                    </Box>
                ) : <Box
                        sx={{
                            marginBottom: '73px',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '30px',
                                fontWeight: '600',
                                color: variables.BLUE
                            }}
                        >
                            {isEditPage ? 'Edit Job' : 'Add a new job'}
                        </Typography>
                        {jobStatus && (
                            <Chip
                                variant='status-chip'
                                label={jobStatus.toUpperCase()}
                                sx={{ mt: 1 }}
                            />
                        )}
                    </Box>
                }
                <Box>
                    {!props.companyId && (
                        <Box>
                            <CustomFormLabel
                                formLabel='Hiring company*'
                                formFieldError={
                                    errors && errors.companyId && errors.companyId.message
                                }
                            />
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ErrorMessage.emptyCompanyId
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <StyleFormControl>
                                        <Autocomplete
                                            id='combo-box-demo'
                                            options={options}
                                            variant='outlined'
                                            value={value}
                                            width={'auto'}
                                            disableClearable
                                            onInputChange={onInputChange}
                                            onChange={(e, data) => {
                                                if (data) {
                                                    onChange(data)
                                                } else {
                                                    onChange('')
                                                }
                                            }}
                                            getOptionLabel={option =>
                                                option ? option.companyName : ''
                                            }
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{
                                                        border: `3px solid ${variables.CYAN}`,
                                                        borderRadius: '20px'
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            sx={{
                                                width: 'auto',
                                                '& .MuiAutocomplete-endAdornment': { pr: '20px' }
                                            }}
                                            MenuProps={{
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                },
                                                PaperProps: {
                                                    sx: {
                                                        maxWidth: '290px',
                                                        width: '290px',
                                                        borderRadius: '30px',
                                                        border: `3px solid ${variables.CYAN}`,
                                                        backgroundColor: variables.WHITE1
                                                    }
                                                },
                                                MenuListProps: {
                                                    sx: {
                                                        paddingTop: '0'
                                                    }
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextInput
                                                    type='text'
                                                    placeholder='Select company'
                                                    fullWidth={true}
                                                    {...params}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>{option.companyName}</li>
                                            )}
                                            popupIcon={<Icons src={DownArrow} />}
                                        >
                                            {options &&
                                                options.map((option, index) =>
                                                    option.id ? (
                                                        <StyledMenuItems value={option.id}>
                                                            {option.name}
                                                        </StyledMenuItems>
                                                    ) : (
                                                        <StyledMenuItems value={option.value}>
                                                            {option.label}
                                                        </StyledMenuItems>
                                                    )
                                                )}
                                        </Autocomplete>
                                    </StyleFormControl>
                                )}
                                name='companyId'
                                {...register('companyId')}
                            />
                            {/* {errors && errors.companyId && errors.companyId.message ? (
                                <FormHelperText id='my-helper-text'>
                                    {errors.companyId.message}
                                </FormHelperText>
                            ) : null} */}
                        </Box>
                    )}
                    <Box {...register('jobTitle')}>
                        <CustomFormLabel
                            formLabel='Job title*'
                            formFieldError={errors && errors.jobTitle && errors.jobTitle.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyJobTitle
                                },
                                pattern: {
                                    value: /^[^\-:,\/()äöüß&!”"*;\\][a-zA-Z :\-,\/()äöüß&!"”*;\\]*$/,
                                    message: 'Please enter valid job title'
                                },
                                validate: title => {
                                    return title.trim().length > 2 || 'Please enter valid job title'
                                }
                            }}
                            name='jobTitle'
                            type='text'
                            placeholder='E.g. Head of Customer relations'
                            errors={errors && errors.jobTitle && errors.jobTitle.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}

                            // ref={register('jobTitle').ref}
                        />
                        <Typography
                            align='left'
                            gutterBottom
                            sx={{
                                marginTop: '18px',
                                marginLeft: '10px !important',
                                fontSize: '15px',
                                mx: {
                                    xs: '20px',
                                    sm: '0px',
                                    md: '0px',
                                    lg: '0px',
                                    xl: '0px'
                                },
                                color: variables.BLUE
                            }}
                        >
                            {TextStrings.addJobTitle}
                        </Typography>
                    </Box>
                    <Box {...register('experience')}>
                        <CustomFormLabel
                            formLabel='Experience'
                            formFieldError={
                                errors && errors.experience && errors.experience.message
                            }
                        />
                        <ControlledRadioGroup
                            control={control}
                            name='experience'
                            options={experiencesList}
                            errors={errors && errors.experience && errors.experience.message}
                            register={register}
                        />
                    </Box>
                    <Box {...register('jobTypes')}>
                        <CustomFormLabel
                            formLabel='Job type*'
                            formFieldError={errors && errors.jobTypes && errors.jobTypes.message}
                        />
                        <ControlledRadioGroup
                            control={control}
                            register={register}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyJobType
                                }
                            }}
                            name='jobTypes'
                            options={allJobTypes}
                            errors={errors && errors.jobTypes && errors.jobTypes.message}
                            handleById={true}
                        />
                    </Box>
                    <Box {...register('location')}>
                        <CustomFormLabel
                            formLabel='Location*'
                            formFieldError={errors && errors.location && errors.location.message}
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            rules={{
                                validate: value => {
                                    return value.length > 0 || ErrorMessage.emptyLocation
                                }
                            }}
                            name='location'
                            options={allStates}
                            errors={errors && errors.location && errors.location.message}
                            handleMutliSelect={handleMutliSelect}
                            formLabel='Location*'
                        />
                    </Box>

                    {getAdminLayoutForIndAndDept()}

                    <Box {...register('jobDescription')}>
                        <Box sx={{ display: {
                            xs:'block',
                            sm: 'flex'
                        }, alignItems: 'center', position:'relative' }}>
                            <CustomFormLabel
                                formLabel='Job Description*'
                                formFieldError={
                                    errors && errors.jobDescription && errors.jobDescription.message
                                }
                            />
                            { userRole === 'admin' && urlParams.jobId && jobStatus === 'draft' && stripHtml(watchJd).result === stripHtml(jobDescriptionState).result && (
                                <Button
                                    label='Send for Review'
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    onClick={() => setSendForReviewModal(true)}
                                    sx={{
                                        py: 1,
                                        px: 2,
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        position:{
                                            xs:'relative',
                                            sm: 'absolute'
                                        },
                                        right: 0,
                                        mt: {
                                            xs: 0,
                                            sm: '30px'
                                        },
                                        ml: {
                                            xs: 4,
                                            sm: 0
                                        },
                                        mb: {
                                            xs: 2,
                                            sm: 0
                                        }
                                    }}
                                />
                            )}
                        </Box>
                        <Controller
                            control={control}
                            rules={{
                                validate: {
                                    required: v =>
                                        (v && stripHtml(v).result.length > 0) ||
                                        ErrorMessage.emptyJobDescription
                                }
                            }}
                            defaultValue=''
                            render={({ field }) => (
                                <WYSIWYGEditor
                                    name='jobDescription'
                                    {...field}
                                    register={register}
                                />
                            )}
                            name='jobDescription'
                        />
                    </Box>
                    <Box {...register('benefits')}>
                        <CustomFormLabel
                            formLabel='Benefits'
                            formFieldError={
                                (errors && errors.benefits && errors.benefits.message) ||
                                (errors && errors.otherBenefits && errors.otherBenefits.message)
                            }
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            // rules={{
                            //     validate: (value) => {
                            //         return value.length > 0 || ErrorMessage.emptyBenefits
                            //     }
                            // }}
                            otherFieldRules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyOthers
                                }
                            }}
                            name='benefits'
                            options={benefitData}
                            errors={errors && errors.benefits && errors.benefits.message}
                            otherFieldErrors={
                                errors && errors.otherBenefits && errors.otherBenefits.message
                            }
                            handleMutliSelect={handleMutliSelect}
                            setValue={() => setValue('otherBenefits')}
                        />
                    </Box>
                    <Box {...register('externalLink')}>
                        <CustomFormLabel
                            formLabel='External job link'
                            formFieldError={
                                errors && errors.externalLink && errors.externalLink.message
                            }
                        />
                        <ControlledTextInput
                            control={control}
                            register={register}
                            rules={{
                                pattern: {
                                    value: Regex.websiteRegex,
                                    message: ErrorMessage.validJobLink
                                }
                            }}
                            name='externalLink'
                            type='text'
                            placeholder='E.g. www.creative-rocket.com/jobs'
                            errors={errors && errors.externalLink && errors.externalLink.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    {apiError && apiError?.message ? (
                        <FormHelperText id='my-helper-text' error={true}>
                            <strong>{apiError?.message}</strong>
                        </FormHelperText>
                    ) : null}
                </Box>
                <Grid
                    container
                    spacing={2}
                    alignItems='center'
                    direction={{
                        xs: 'column-reverse',
                        sm: 'row'
                    }}
                    sx={{
                        mt: 9,
                        mb: 2,
                        textAlign: 'center'
                    }}
                >
                    {(urlParams.jobId && jobStatus === 'published') && <Grid item md={6} sm={6} xs={12} sx={{ textAlign: {
                        xs:' center',
                        sm: 'right'
                    } }}>
                    <Button
                        label='Cancel'
                        color='secondary'
                        variant='contained'
                        size='large'
                        sx={{
                            padding: '13px 30px',
                            fontSize: '14px',
                            fontWeight: '600',
                            minWidth: {
                                xs: '200px',
                                sm: 'auto'
                            }
                        }}
                        onClick={() => {
                            if (props.companyId) {
                                if (location.pathname.includes('/admin-portal/add-job/')) {
                                    navigate(-1)
                                } else {
                                    navigate('/dashboard')
                                }
                            } else {
                                navigate('/admin-portal/job-list')
                            }
                        }}
                    />
                </Grid>}
                    {((urlParams.jobId && jobStatus === 'draft') || !urlParams.jobId) && (
                        <Grid item md={6} sm={6} xs={12} sx={{ textAlign: 'right' }}>
                            <Button
                                label={
                                    isSaving ? (
                                        <Box sx={{ display: 'flex' }}>
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: variables.BLUE
                                                }}
                                            />
                                            <Typography sx={{ ml: 2 }}>Saving...</Typography>
                                        </Box>
                                    ) : (
                                        'Save as Draft'
                                    )
                                }
                                disabled={isSaving || isPublishing}
                                color='primary'
                                variant='contained'
                                size='large'
                                onClick={handleSubmit(saveAsDraft)}
                                sx={{
                                    padding: '13px 30px',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    minWidth: {
                                        xs: '200px',
                                        sm: 'auto'
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item md={6} sm={6} xs={12} sx={{ textAlign: 'left' }}>
                        <Button
                            label={
                                isPublishing ? (
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress
                                            size={20}
                                            sx={{
                                                color: variables.BLUE
                                            }}
                                        />
                                        <Typography sx={{ ml: 2 }}>Publishing...</Typography>
                                    </Box>
                                ) : jobStatus === 'published' ? (
                                    'Update'
                                ) : (
                                    'Publish to Bunton'
                                )
                            }
                            disabled={isPublishing || isSaving}
                            color='primary'
                            variant='contained'
                            size='large'
                            onClick={handleSubmit(formSubmit)}
                            sx={{
                                padding: '13px 30px',
                                fontSize: '14px',
                                fontWeight: '600',
                                minWidth: {
                                    xs: '200px',
                                    sm: 'auto'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                {jobDetailLoading && <Loader />}
                {isSuccess ? <StatusBox statusText={successMessage} /> : null}
                {sendForReviewModal && <SendJobForReviewModal open={sendForReviewModal} handleClose= {() => setSendForReviewModal(false)} jobPostId={urlParams.jobId}/>}
            </Container>
        </Box>
    )
}

export default AddJobComponent
