import variables from '../../settings/_variables.scss'
export const styles = {
    container: {
        backgroundColor: variables.WHITE1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    styleOneContainer: {
        maxWidth: '814px',
        flexDirection: 'column',
        margin: 'auto',
        textAlign: 'center',
        pl: '12px',
        pr: '12px'
    },
    header: {
        mb: '36px',
        mt: '40px',
        display: 'flex'
    },
    logo: {
        width: '122px',
        height: '40px',
        flex: 1
    },
    backIconContainer: {
        height: '40px',
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        ml: {
            xs: '16px',
            sm: '30px',
            md: '80px',
            lg: '80px',
            xl: '80px'
        }
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    chatContainer: {
        // maxWidth: '814px',
        maxWidth: {
            xs: '100%',
            sm: '90%',
            md: '900px',
            lg: '1200px'
        },
        display: 'flex',
        alignItems: 'end'
    },
    chatScrollableContainer: {
        maxHeight: '50vh',
        // width: '50vw',
        width: {
            xs: '100%',
            sm: '100%',
            md: '50vw',
            lg: '50vw',
            xl: '50vw'
        },
        overflow: 'auto'
    },
    msgWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        pl: '10px',
        pr: '10px'
    },
    msgContainer: {
        pl: '50px',
        pr: '50px',
        flex: 1
    },
    msgLeft: {
        // maxWidth: '574px',
        maxWidth: {
            xs: '80%',
            sm: '80%',
            md: '574px',
            lg: '574px',
            xl: '574px'
        },
        border: `2px solid ${variables.BLUE}`,
        padding: 3,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular
    },
    msgLeftLoader: {
        // maxWidth: '10%',
        maxWidth: {
            xs: '20%',
            sm: '10%',
            md: '10%',
            lg: '10%',
            xl: '10%'
        },
        border: `2px solid ${variables.BLUE}`,
        padding: 1,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular
    },
    msgRight: {
        maxWidth: '574px',
        padding: 4,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        border: `2px solid ${variables.CYAN}`,
        mr: '10px'
    },
    avatarContainer: {
        position: 'absolute',
        bottom: 0
    },
    chipWrapper: {
        display: 'flex',
        justifyContent: 'center',
        // pl: '60px',
        pl: {
            xs: '10px',
            sm: '10px',
            md: '60px',
            lg: '60px',
            xl: '60px'
        },
        pb: '10px',
        flexWrap: 'wrap'
    },
    chipContainer: {
        p: 2,
        mt: 2,
        mr: 2,
        backgroundColor: variables.CYAN1,
        borderColor: variables.CYAN1
    },
    registrationStepContainer: {
        // width: '814px',
        width: {
            xs: '100%',
            sm: '100%',
            md: '814px',
            lg: '814px',
            xl: '814px'
        }
    },
    registrationFormContainer: {
        width: '100%',
        display: 'block',
        alignContent: 'center',
        mt: '30px',
        alignItems: 'center',
        flexDirection: 'column'
    },
    emailContainer: { width: '80%' },
    passwordContainer: { width: '80%', mt: '10px' },
    or: { mt: '30px', mb: '23px', color: `${variables.BLUE}` },
    socialLoginContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'center',
        alignContent: 'center'
    },
    socialLoginButton: {
        paddingLeft: '50px',
        paddingRight: '50px',
        border: `5px solid ${variables.WHITE1}`,
        boxShadow: 'none',
        marginBottom: '20px'
    },
    submitContainer: {
        mt: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center'
    },
    textInput: {
        // width: '40vw',
        width: {
            xs: '70vw',
            sm: '40vw',
            md: '40vw',
            lg: '40vw',
            xl: '40vw'
        }
    },
    submitButton: {
        paddingLeft: '50px',
        paddingRight: '50px',
        border: `5px solid ${variables.WHITE1}`,
        boxShadow: 'none',
        mt: '18px',
        mb: '18px'
    },
    welcomeLogoBox: {
        marginBottom: '50px',
        marginTop: '50px'
    },
    welcomeLogo: {
        width: '240px',
        height: '65px'
    },
    welcomeToButon: {
        // fontSize: '81px',
        fontSize: {
            xs: '70px',
            sm: '81px',
            md: '81px',
            lg: '81px',
            xl: '81px'
        },
        color: variables.CYAN,
        fontWeight: '600',
        fontFamily: variables.poppinsRegular
    },
    welcomeToButonText: {
        // width: '100%',
        width: {
            xs: '100%',
            sm: '395px',
            md: '395px',
            lg: '395px',
            xl: '395px'
        },
        fontSize: '15px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        textAlign: 'center'
    },
    formLogoBox: {
        margin: '0 auto'
    },
    startButton: {
        pl: '50px',
        pr: '50px',
        border: `5px solid ${variables.WHITE1}`,
        boxShadow: 'none',
        mt: '52px',
        fontFamily: variables.poppinsRegular,
        fontSize: '18px'
    },
    alreadyHaveAccText: {
        color: variables.BLUE,
        fontSize: '15px',
        fontFamily: variables.poppinsRegular,
        mt: '36px',
        mb: '10px'
    },
    link: {
        color: variables.BLUE,
        fontSize: '15px',
        fontFamily: variables.poppinsRegular,
        textDecorationColor: variables.BLUE
    },
    Loader: {
        color: variables.BLUE
    }
}
