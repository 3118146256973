import {
    Box,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    ImageListItem,
    InputAdornment,
    Link,
    Menu,
    MenuItem,
    Typography
} from '@mui/material'
import React, { useState } from 'react'
import variables from '../../../settings/_variables.scss'
import { Button, TextInput } from '../../../components'
import { StyleFormControl } from '../../../base/commonStyles'
import { experiencesList } from '../../../utils/constantData'
import PageMetaTags from '../../../base/PageMetaTags'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorMessage, keys, Regex } from '../../../constants'
import { AVATAR, DateDefaultFromat, LANGUAGES } from '../../../constants/constants'
import Loader from '../../../components/Loader'
import { USER_DETAILS } from '../../../queries/userDetailsQuery'
import { MANAGE_USER_PROFILE_MUTATION } from '../../../gql/queries'
import { DELETE_USER_MUTATION } from '../../../gql/queries'
import Layout from '../../../template/Layout'
import Icons from '../../../components/Icons'
import StatusBox from '../../../components/StatusBox'
import Cookies from 'universal-cookie'
import { addHasResume, deleteAllProfileDetails } from '../../../redux/actions/profileDetail'
import { logoutSuccess, setAvatar } from '../../../redux/actions/login'
import DeleteEntityBox from '../../../components/DeleteEntityBox'
import PageHeaderWithBackButton from '../../../components/elements/PageHeaderWithBackButton'
import ControlledTextInput from '../../../components/molecules/ControlledTextInput'
import ControlledRadioGroup from '../../../components/molecules/ControlledRadioGroup'
import ControlledCheckboxGroup from '../../../components/molecules/ControlledCheckboxGroup'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { DirectUpload } from 'activestorage'
import moment from 'moment'
import WYSIWYGEditor from '../../../components/WYSIWYGEditor'
import { stripHtml } from 'string-strip-html'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CustomFormLabel from '../../../components/elements/CustomFormLabel'

const cookies = new Cookies()

const uploadFile = (file, onMutate, data) => {
    // TODO: Make this URL not static
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'resume')

    upload.create((error, blob) => {
        if (error) {
            // Handle the error
        } else {
            // console.log(blob.signed_id)
            // return blob.signed_id
            // Add an appropriately-named hidden input to the form with a
            //  value of blob.signed_id so that the blob ids will be
            //  transmitted in the normal upload flow
            onMutate({
                variables: {
                    ...data,
                    candidateResume: blob.signed_id
                } 
            })
            // TODO redirect to photo detail page?
        }
    })
}

const EditProfile = props => {
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [deleteProfileModal, setDeleteProfileModal] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleMoreOptionClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleMoreOptionClose = () => {
        setAnchorEl(null)
    }

    const dispatch = useDispatch()

    const { allIndustries, allStates, allBenefits, allDepartments } = useSelector(
        state => state.initial
    )
    const { loginCheckAuthDetails, loginDetails } = useSelector(state => state.login)
    const [apiError, setApiError] = useState('')

    const userId = loginCheckAuthDetails.id
        ? loginCheckAuthDetails.id
        : loginDetails.id
        ? loginDetails.id
        : 0

    let initialFormState = {
        name: '',
        email: '',
        profession: '',
        experience: '',
        location: '',
        languages: '',
        department: '',
        otherDepartment: '',
        industry: '',
        otherIndustry: '',
        otherBenefit: '',
        about: '',
        benefit: '',
        profileImage: '',
        candidateResume: '',
        linkedinUrl: ''
    }

    const {
        loading,
        data: userData,
        error
    } = useQuery(USER_DETAILS, {
        variables: { id: userId },
        fetchPolicy: 'network-only',
        onCompleted(data) {
            let formData = {
                name: data.user.name,
                email: data.user.email,
                profession: data.user.candidate.title,
                experience: data.user.candidate.experience,
                location: data.user.candidate.states.map(item => {
                    return item.id
                }),
                languages: data.user.candidate.language
                    ? data.user.candidate.language.split(',')
                    : '',
                industry: data.user.candidate.industries.map(item => {
                    return item.id
                }),
                otherIndustry: data.user.candidate.otherIndustry,
                department: data.user.candidate.departments.map(item => {
                    return item.id
                }),
                otherDepartment: data.user.candidate.otherDepartment,
                otherBenefit: data.user.candidate.otherBenefits,
                about: data.user.candidate.about,
                benefit: data.user.candidate.benefits.map(item => {
                    return item.id
                }),
                profileImage: data.user.profileImage,
                candidateResume: data.user.candidate.candidateResumeLink,
                linkedinUrl: data.user.linkedinUrl
            }
            reset(formData)
        }
    })

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
        resetField,
        watch,
        register,
        setFocus,
        setValue
    } = useForm({
        defaultValues: initialFormState
    })
    React.useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
            return !!errors[field] ? field : a
        }, null)
        if (firstError) {
            setFocus(firstError)
        }
    }, [errors, setFocus])

    const aboutWatch = watch('about')

    /** api integration graphql */
    const [manageProfileDetailMutation, { data }] = useMutation(MANAGE_USER_PROFILE_MUTATION, {
        onCompleted(data) {
            dispatch(setAvatar(data.manageUserProfile.user.profileImage))
            setSuccessMessage('Profile Updated Successfully')
            setIsSaving(false)
            setIsSuccess(true)
            let resumeFormData = []
            resumeFormData['key'] = keys.uploadResume
            resumeFormData['data'] = true
            setTimeout(() => {
                setIsSuccess(false)
                dispatch(addHasResume(resumeFormData))
                navigate(-1)
            }, 3000)
        },
        onError(error) {
            setApiError(error)
            // dispatch(addProfileDetailFailure(error.message))
        }
    })

    const [deleteUserMutation, { loading: deleteLoading }] = useMutation(DELETE_USER_MUTATION, {
        onCompleted(delete_data) {
            dispatch(logoutSuccess(delete_data))
            dispatch(deleteAllProfileDetails(delete_data))
            cookies.remove('BUNTON_ACCESS_TOKEN')
            cookies.remove('BUNTON_CLIENT_TOKEN')
            cookies.remove('BUNTON_EXPIRY')
            cookies.remove('BUNTON_UID')
            setDeleteProfileModal(false)
            navigate('/')
        },
        onError(error) {
            setApiError(error)
            // dispatch(addProfileDetailFailure(error.message))
        }
    })

    const deleteProfile = value => {
        deleteUserMutation({ variables: { id: userId } })
        navigate('/logout')
    }

    const handleDeleteClose = () => {
        setDeleteProfileModal(false)
    }

    const formSubmit = async data => {
        setApiError('')

        let formData = {
            name: data.name,
            title: data.profession,
            experience: data.experience,
            location: data.location.join(),
            language: data.languages.join(),
            industryIds: data.industry.join(),
            otherIndustry: data.otherIndustry,
            departmentId: data.department.join(),
            otherDepartment: data.otherDepartment,
            otherBenefits: data.otherBenefit,
            about: data.about,
            benefitIds: data.benefit.join(),
            profileImage: data.profileImage,
            actionType: 'update',
            linkedinUrl: data.linkedinUrl
        }
        if (data.candidateResume && data.candidateResume.size && data.candidateResume.size > 0) {
            // const previousFile = data.candidateResume
            // const filename = previousFile.name
            // let last_dot = filename.lastIndexOf('.')
            // let ext = filename.slice(last_dot + 1)
            // const userName = data.name.trim().replace(/\s+/g, '_')
            // // newFile = new File([previousFile], `${userName}.${ext}`)
            // await uploadFileToBlob(newFile)
            await uploadFile(data.candidateResume, manageProfileDetailMutation, formData)
            // console.log(newFile)
        } else {
            manageProfileDetailMutation({
                variables: {
                    ...formData
                }
            })
        }
        setIsSaving(true)
    }

    function handleMutliSelect(checkedName, fieldName) {
        const newNames = getValues(fieldName)?.includes(checkedName)
            ? getValues(fieldName)?.filter(name => name !== checkedName)
            : [...(getValues(fieldName) ?? []), checkedName]

        return newNames
    }

    const avatarList = (onChange, value) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: {
                        xs: 'wrap',
                        sm: 'wrap',
                        md: 'nowrap',
                        lg: 'nowrap',
                        xl: 'nowrap'
                    },
                    justifyContent: 'center'
                }}
            >
                {AVATAR.map((item, index) => (
                    <ImageListItem key={item.name}>
                        <IconButton
                            onClick={() => {
                                onChange(item.name)
                            }}
                            sx={{}}
                        >
                            <Icons
                                sx={{
                                    height: '114px',
                                    width: '114px',
                                    borderRadius: '70px',
                                    border:
                                        value === item.name
                                            ? `6px solid ${variables.BLUE}`
                                            : `3px solid ${variables.CYAN}`
                                }}
                                src={require(`../../../assets/icons/AVATAR/${item.name}.png`)}
                            />
                        </IconButton>
                    </ImageListItem>
                ))}
            </Box>
        )
    }

    const [inputKey] = useState(Math.random().toString(36))
    const hiddenFileInput = React.useRef(null)

    return (
        <Layout>
            <PageMetaTags title={'Edit Profile'} />
            <PageHeaderWithBackButton pageTitle='Edit your profile' />

            <Container
                maxWidth='988px'
                disableGutters
                sx={{
                    padding: {
                        xs: '0px 40px',
                        sm: '0px 80px',
                        md: '0px 146px'
                    },
                    maxWidth: {
                        xs: '100vw',
                        sm: '100vw',
                        md: '988px'
                    }
                }}
            >
                <Box>
                    <Box {...register('name')}>
                        <CustomFormLabel
                            formLabel='Your Name*'
                            formFieldError={errors && errors.name && errors.name.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyName
                                },
                                pattern: {
                                    value: /^[a-zA-Z ]*$/,
                                    message:
                                        'Name should contain minimum 2 characters and no special characters.'
                                },
                                validate: title => {
                                    return (
                                        title.trim().length >= 2 ||
                                        'Name should contain minimum 2 characters and no special characters.'
                                    )
                                }
                            }}
                            name='name'
                            type='text'
                            placeholder='E.g. Dr. Sarah Müller'
                            errors={errors && errors.name && errors.name.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    <Box>
                        <CustomFormLabel
                            formLabel='Your E-mail address*'
                            formFieldError={errors && errors.email && errors.email.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyEmail
                                },
                                pattern: {
                                    value: Regex.emailRegex,
                                    message: ErrorMessage.validEmail
                                }
                            }}
                            name='email'
                            type='text'
                            placeholder='sarah.mueller@mailing.com'
                            errors={errors && errors.email && errors.email.message}
                            required={true}
                            fullWidth={true}
                            disabled={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    <Box {...register('profession')}>
                        <CustomFormLabel
                            formLabel='Your Profession*'
                            formFieldError={
                                errors && errors.profession && errors.profession.message
                            }
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyProfession
                                }
                            }}
                            name='profession'
                            type='text'
                            placeholder='E.g. Senior Software Engineer'
                            errors={errors && errors.profession && errors.profession.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    <Box {...register('experience')}>
                        <CustomFormLabel
                            formLabel='Your experience*'
                            formFieldError={
                                errors && errors.experience && errors.experience.message
                            }
                        />
                        <ControlledRadioGroup
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyExperience
                                }
                            }}
                            register={register}
                            name='experience'
                            options={experiencesList}
                            errors={errors && errors.experience && errors.experience.message}
                        />
                    </Box>

                    <Box {...register('location')}>
                        <CustomFormLabel
                            formLabel='Your location*'
                            formFieldError={errors && errors.location && errors.location.message}
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            rules={{
                                validate: value => {
                                    return value.length > 0 || ErrorMessage.emptyLocation
                                }
                            }}
                            name='location'
                            options={allStates}
                            errors={errors && errors.location && errors.location.message}
                            handleMutliSelect={handleMutliSelect}
                        />
                    </Box>

                    <Box {...register('languages')}>
                        <CustomFormLabel
                            formLabel='Your languages*'
                            formFieldError={errors && errors.languages && errors.languages.message}
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            rules={{
                                validate: value => {
                                    return value.length > 0 || ErrorMessage.emptyLanguage
                                }
                            }}
                            name='languages'
                            options={LANGUAGES}
                            errors={errors && errors.languages && errors.languages.message}
                            handleMutliSelect={handleMutliSelect}
                            handleByName={true}
                        />
                    </Box>

                    <Box {...register('industry')}>
                        <CustomFormLabel
                            formLabel='Your preferred industries*'
                            formFieldError={errors && errors.industry && errors.industry.message}
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            rules={{
                                validate: value => {
                                    return value.length > 0 || ErrorMessage.emptyIndustries
                                }
                            }}
                            otherFieldRules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyOthers
                                }
                            }}
                            name='industry'
                            options={allIndustries}
                            errors={errors && errors.industry && errors.industry.message}
                            otherFieldErrors={
                                errors && errors.otherIndustry && errors.otherIndustry.message
                            }
                            handleMutliSelect={handleMutliSelect}
                            resetField={() => setValue('otherIndustry', '')}
                        />
                    </Box>

                    <Box {...register('department')}>
                        <CustomFormLabel
                            formLabel='Your Department*'
                            formFieldError={
                                errors && errors.department && errors.department.message
                            }
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            rules={{
                                validate: value => {
                                    return value.length > 0 || ErrorMessage.emptyDepartment
                                }
                            }}
                            otherFieldRules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyOthers
                                }
                            }}
                            name='department'
                            options={allDepartments}
                            errors={errors && errors.department && errors.department.message}
                            otherFieldErrors={
                                errors && errors.otherDepartment && errors.otherDepartment.message
                            }
                            handleMutliSelect={handleMutliSelect}
                            resetField={() => setValue('otherDepartment', '') }
                        />
                    </Box>

                    <Box>
                        <CustomFormLabel
                            formLabel='Your avatar*'
                            formFieldError={
                                errors && errors.profileImage && errors.profileImage.message
                            }
                        />
                        <Controller
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyAvatar
                                }
                            }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <>
                                        {avatarList(onChange, value)}
                                        {/* {errors &&
                                    errors.profileImage &&
                                    errors.profileImage.message ? (
                                        <FormHelperText id='my-helper-text'>
                                            {errors.profileImage.message}
                                        </FormHelperText>
                                    ) : null} */}
                                    </>
                                )
                            }}
                            name='profileImage'
                        />
                    </Box>

                    <Box {...register('candidateResume')}>
                        <CustomFormLabel
                            formLabel='Upload Resume'
                            formFieldError={
                                errors && errors.candidateResume && errors.candidateResume.message
                            }
                        />
                        {userData &&
                            userData.user &&
                            userData.user.candidate &&
                            userData.user.candidate.candidateResumeLink && (
                                <Box
                                    sx={{
                                        p: 2,
                                        px: 4,
                                        my: 1,
                                        display: 'flex',
                                        border: `1px solid ${variables.BLUE}`,
                                        background: variables.CYAN1,
                                        borderRadius: '50px'
                                    }}
                                >
                                    <Box sx={{ width: '60%' }}>
                                        <Link
                                            href={
                                                userData &&
                                                userData.user &&
                                                userData.user.candidate &&
                                                userData.user.candidate.candidateResumeLink
                                            }
                                            sx={{ textDecoration: 'none' }}
                                            target='_blank'
                                        >
                                            <Typography
                                                variant='paragraph'
                                                className='para-font-20px strong'
                                            >
                                                {userData &&
                                                    userData.user &&
                                                    userData.user.candidate &&
                                                    userData.user.candidate.candidateResumeFilename}
                                            </Typography>
                                        </Link>
                                    </Box>
                                    <Box sx={{ width: '40%', textAlign: 'right' }}>
                                        <Typography sx={{ float: 'right' }}>
                                            Uploaded at{' '}
                                            {moment(
                                                userData &&
                                                    userData.user &&
                                                    userData.user.candidate &&
                                                    userData.user.candidate.candidateResumeCreatedat
                                            ).format(DateDefaultFromat)}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        <Controller
                            control={control}
                            rules={{
                                // required: {
                                //     value: true,
                                //     message: ErrorMessage.emptyCompanyLogo
                                // },
                                validate: {
                                    lessThan2MB: file => {
                                        return file && file.size
                                            ? file.size < 2048576 ||
                                                  ErrorMessage.resumeSizeValidation
                                            : true
                                    },
                                    acceptedFormats: file => {
                                        return file && file.size
                                            ? [
                                                  'image/jpeg',
                                                  'image/png',
                                                  'image/jpg',
                                                  'application/pdf'
                                              ].includes(file?.type) ||
                                                  ErrorMessage.resumeTypeValidation
                                            : true
                                    }
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <StyleFormControl>
                                    <input
                                        type='file'
                                        ref={hiddenFileInput}
                                        onChange={event => {
                                            onChange(event.target.files[0])
                                        }}
                                        style={{ display: 'none' }}
                                        key={inputKey || ''}
                                    />
                                    <TextInput
                                        type='Name'
                                        name='candidateResume'
                                        // disabled
                                        onChange={onChange}
                                        formFieldError={
                                            errors &&
                                            errors.candidateResume &&
                                            errors.candidateResume.message
                                        }
                                        required={true}
                                        value={value ? value.name : ''}
                                        fullWidth={true}
                                        inputProps={{ readOnly: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={() => {
                                                            hiddenFileInput.current.click()
                                                        }}
                                                        onMouseDown={() => {}}
                                                    >
                                                        <ArrowRightAltIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </StyleFormControl>
                            )}
                            name='candidateResume'
                        />
                    </Box>

                    <Box {...register('about')}>
                        <CustomFormLabel
                            formLabel='About you*'
                            formFieldError={
                                errors && errors.about && errors.about.message
                            }
                        />
                        <Controller
                            control={control}
                            rules={{
                                validate: {
                                    required: v =>
                                        (v && stripHtml(v).result.length > 0) ||
                                        ErrorMessage.emptyProfileDescription
                                }
                            }}
                            defaultValue=''
                            render={({ field }) => (
                                <WYSIWYGEditor name='about' {...field} register={register} />
                            )}
                            name='about'
                        />
                        {/* {errors && errors.about && errors.about.message ? (
                            <FormHelperText id='my-helper-text'>
                                {errors.about.message}
                            </FormHelperText>
                        ) : null} */}
                        {/* <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyProfileDescription
                                }
                            }}
                            name='about'
                            type='text'
                            placeholder='Add a short description.'
                            errors={errors && errors.name && errors.name.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{width: '100%'}}
                            inputProps={{
                                maxLength: 500
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{ marginLeft: '10px' }}
                                    >
                                        <Box sx={{ position: 'relative' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    ml: '15px',
                                                    fontSize: '15px',
                                                    color: variables.BLUE
                                                }}
                                            >
                                                {`${
                                                    aboutWatch && aboutWatch.length
                                                        ? aboutWatch.length
                                                        : '0'
                                                }`}
                                                /500
                                            </Box>
                                        </Box>
                                    </InputAdornment>
                                )
                            }}
                        /> */}
                    </Box>

                    <Box {...register('linkedinUrl')}>
                        <CustomFormLabel
                            formLabel='LinkedIn Profile URL'
                            formFieldError={
                                errors && errors.linkedinUrl && errors.linkedinUrl.message
                            }
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                pattern: {
                                    value: Regex.linkedInRegex,
                                    message: ErrorMessage.validLinkedInUrl
                                }
                            }}
                            name='linkedinUrl'
                            type='text'
                            errors={errors && errors.linkedinUrl && errors.linkedinUrl.message}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    <Box {...register('benefit')}>
                        <CustomFormLabel
                            formLabel='Your Preferences'
                            formFieldError={
                                errors && errors.benefit && errors.benefit.message
                            }
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            // rules={{
                            //     validate: (value) => {
                            //         return value.length > 0 || ErrorMessage.emptyBenefits
                            //     }
                            // }}
                            name='benefit'
                            otherFieldRules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyOthers
                                }
                            }}
                            options={allBenefits}
                            errors={errors && errors.benefit && errors.benefit.message}
                            otherFieldErrors={
                                errors && errors.otherBenefit && errors.otherBenefit.message
                            }
                            handleMutliSelect={handleMutliSelect}
                            resetField={() => setValue('otherBenefit', '')}
                        />
                    </Box>
                    {apiError && apiError?.message ? (
                        <FormHelperText 
                            id='my-helper-text'
                            error={true}
                        >
                            <strong>{apiError?.message}</strong>
                        </FormHelperText>
                    ) : null}

                    <Grid container sx={{ mt: '74px', mb: 4 }} alignItems='center' spacing={2} direction={{
                        xs:'column-reverse', sm: 'row'
                    }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Box sx={{ textAlign: {
                                xs:'center',sm: 'left'
                            } }}>
                                <Button
                                    id='more-options-button'
                                    aria-controls={open ? 'more-options-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={open ? 'true' : undefined}
                                    variant='contained'
                                    color='secondary'
                                    sx={{
                                        p: '10px 30px',
                                        background: 'transparent',
                                        '&:hover': {
                                            background: 'transparent'
                                        }
                                    }}
                                    disableElevation
                                    onClick={handleMoreOptionClick}
                                    label='More Options'
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    More Options
                                </Button>
                                <Menu
                                    id='more-options-menu'
                                    MenuListProps={{
                                        'aria-labelledby': 'more-options-button'
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMoreOptionClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            background: variables.CYAN,
                                            width: '180px',
                                            maxWidth: '200px'
                                        }
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            setDeleteProfileModal(true)
                                            handleMoreOptionClose()
                                        }}
                                        disableRipple
                                        sx={{
                                            p: 2,
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant='h5'
                                            className='small-size-strong'
                                            sx={{ width: '100%' }}
                                        >
                                            Delete Profile
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ textAlign: {
                            xs:'center', sm: 'right'
                        } }}>
                            <Button
                                label={
                                    isSaving ? (
                                        <Box sx={{ display: 'flex' }}>
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: variables.BLUE
                                                }}
                                            />
                                            <Typography sx={{ ml: 2 }}>Saving...</Typography>
                                        </Box>
                                    ) : (
                                        'Save changes'
                                    )
                                }
                                disabled={isSaving}
                                color='primary'
                                variant='contained'
                                size='large'
                                onClick={handleSubmit(formSubmit)}
                                sx={{
                                    padding: '13px 30px',
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {loading && <Loader />}
                {isSuccess ? <StatusBox statusText={successMessage} /> : null}
            </Container>

            <DeleteEntityBox
                open={deleteProfileModal}
                deleteMutation={() => deleteProfile('')}
                entityId={1}
                deleteLoading={deleteLoading}
                closeDelete={handleDeleteClose}
            />
        </Layout>
    )
}

export default EditProfile
