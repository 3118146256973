import { Box, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DirectUpload } from 'activestorage'
import { useMutation } from '@apollo/client'
import { MANAGE_USER_PROFILE_MUTATION } from '../../gql/queries'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage, keys } from '../../constants'
import { StyleFormControl } from '../../base/commonStyles'
import TextInput from '../TextInput'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Button from '../Button'
import CustomFormLabel from '../elements/CustomFormLabel'
import variables from '../../settings/_variables.scss'
import { useDispatch } from 'react-redux'
import { addHasResume } from '../../redux/actions/profileDetail'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const uploadFile = (file, onMutate, data) => {
    // TODO: Make this URL not static
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'resume')

    upload.create((error, blob) => {
        if (error) {
            // Handle the error
        } else {
            // Add an appropriately-named hidden input to the form with a
            // value of blob.signed_id so that the blob ids will be
            // transmitted in the normal upload flow
            onMutate({
                variables: {
                    ...data,
                    candidateResume: blob.signed_id
                }
            })
            // TODO redirect to photo detail page?
        }
    })
}

const UploadResumeSection = props => {
    const dispatch = useDispatch()
    const [inputKey] = useState(Math.random().toString(36))
    const hiddenFileInput = React.useRef(null)
    const [uploading, setUploading] = useState(false)
    const [uploaded, setUploaded] = useState(false)
    const [uploadMessage, setUploadMessage] = useState('')

    let initialFormState = {
        candidateResume: ''
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        register
    } = useForm({
        defaultValues: initialFormState
    })
    const [manageProfileDetailMutation, { data }] = useMutation(MANAGE_USER_PROFILE_MUTATION, {
        onCompleted(data) {
            setUploading(false)
            setUploaded(true)
            setUploadMessage('Resume Uploaded Successfully')
            
            setTimeout(() => {
                let formData = []
                formData['key'] = keys.uploadResume
                formData['data'] = true
                dispatch(addHasResume(formData))
                props.modalClose()
            }, 3000)
        },
        onError(error) {
            // setApiError(error)
            // dispatch(addProfileDetailFailure(error.message))
        }
    })

    const uploadResume = async data => {
        setUploading(true)
        if (data.candidateResume && data.candidateResume.size && data.candidateResume.size > 0) {
            await uploadFile(data.candidateResume, manageProfileDetailMutation, data)
        }
    }

    return (
        <Box>
            <Box>
                <Box {...register('candidateResume')}>
                    {!props.hideTitle && (
                        <CustomFormLabel
                            formLabel='Upload Resume'
                            formFieldError={
                                errors && errors.candidateResume && errors.candidateResume.message
                            }
                            formFieldErrorSx={{
                                textAlign: 'center'
                            }}
                            labelSx={{
                                mt: 2,
                                ml: 0,
                                mb: 1
                            }}
                        />
                    )}
                    <Typography variant='h5' className='small-size' sx={{ my: 1 }}>
                        By uploading your resume, you’ll help us match your skills and experience
                        with the perfect role.
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ErrorMessage.emptyResume
                            },
                            validate: {
                                lessThan2MB: file => {
                                    return file && file.size
                                        ? file.size < 2048576 || ErrorMessage.resumeSizeValidation
                                        : true
                                },
                                acceptedFormats: file => {
                                    return file && file.size
                                        ? [
                                              'image/jpeg',
                                              'image/png',
                                              'image/jpg',
                                              'application/pdf'
                                          ].includes(file?.type) ||
                                              ErrorMessage.resumeTypeValidation
                                        : true
                                }
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <StyleFormControl>
                                <input
                                    type='file'
                                    ref={hiddenFileInput}
                                    onChange={event => {
                                        onChange(event.target.files[0])
                                    }}
                                    style={{ display: 'none' }}
                                    disabled={uploaded}
                                    key={inputKey || ''}
                                />
                                <TextInput
                                    type='Name'
                                    name='candidateResume'
                                    disabled={uploaded}
                                    onChange={onChange}
                                    formFieldError={
                                        errors &&
                                        errors.candidateResume &&
                                        errors.candidateResume.message
                                    }
                                    required={true}
                                    value={value ? value.name : ''}
                                    fullWidth={true}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={() => {
                                                        hiddenFileInput.current.click()
                                                    }}
                                                    onMouseDown={() => {}}
                                                >
                                                    <ArrowRightAltIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </StyleFormControl>
                        )}
                        name='candidateResume'
                    />
                </Box>
                {uploaded ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 2
                        }}
                    >
                        <CheckCircleIcon sx={{ width: '20px', mr: 1, color: variables.GREEN }} />
                        <Typography variant='h5' className='small-size-strong green-color'>
                            {uploadMessage}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        label={
                            uploading ? (
                                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                    <CircularProgress
                                        size={20}
                                        sx={{ color: variables.BLUE, mr: 2 }}
                                    />
                                    <Typography
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'block'
                                            },
                                            fontSize: '16px',
                                            fontWeight: '900'
                                        }}
                                    >
                                        Uploading...
                                    </Typography>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: '900'
                                        }}
                                    >
                                        Upload
                                    </Typography>
                                </Box>
                            )
                        }
                        disabled={uploading}
                        color='primary'
                        variant='contained'
                        size='large'
                        onClick={handleSubmit(uploadResume)}
                        sx={{
                            mt: 2,
                            padding: '13px 30px',
                            fontSize: '14px',
                            fontWeight: '600'
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default UploadResumeSection
