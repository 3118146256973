import { gql } from '@apollo/client'

export const USER_DETAILS = gql`
    query GetUserDetails($id: ID!) {
        user(id: $id) {
            id
            name
            userRole
            profileImage
            linkedinUrl
            email
            candidate {
                id
                location
                experience
                language
                about
                title
                candidateResumeLink
                candidateResumeCreatedat
                candidateResumeFilename
                benefits {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                otherDepartment
                otherIndustry
                otherBenefits
                educationDetails {
                    id
                    certificateDegreeName
                    major
                    yearOfCompletion
                }
                experienceDetails {
                    id
                    endDate
                    startDate
                    isCurrentJob
                    jobTitle
                    companyName
                }
                departments {
                    id
                    name
                }
                otherDepartment
                states {
                    id
                    name
                }
                isUserConnected
            }
            company {
                id
                companyName
                companyLogoUrl
                alternateEmail
                user {
                    id
                    profileImage
                }
                companyWebsite
                description
                noOfEmployees
                otherDepartment
                otherIndustry
                otherBenefits
                states {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                jobs {
                    id
                    jobTitle
                    states {
                        id
                        name
                    }
                    industries {
                        id
                        name
                    }
                    departments {
                        id
                        name
                    }
                }
                benefits {
                    id
                    name
                }
            }
        }
    }
`

export const ANONYMOUS_USER_DETAILS = gql`
    query GetUserDetails($id: ID!) {
        user(id: $id) {
            id
            profileImage
            userRole
            candidate {
                id
                location
                experience
                language
                title
                otherDepartment
                otherIndustry
                otherBenefits
                benefits {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                educationDetails {
                    id
                    certificateDegreeName
                    major
                    yearOfCompletion
                }
                experienceDetails {
                    id
                    endDate
                    startDate
                    isCurrentJob
                    jobTitle
                    companyName
                }
                departments {
                    id
                    name
                }
                isUserConnected
                isUserInvited
            }
            company {
                id
                companyName
                companyLogoUrl
                user {
                    id
                    profileImage
                }
                companyWebsite
                description
                noOfEmployees
                otherDepartment
                otherIndustry
                otherBenefits
                states {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                jobs {
                    id
                    jobTitle
                    states {
                        id
                        name
                    }
                    industries {
                        id
                        name
                    }
                    departments {
                        id
                        name
                    }
                }
                benefits {
                    id
                    name
                }
            }
        }
    }
`

export const ANONYMOUS_CHECK = gql`
    query GetUserDetails($id: ID!, $jobId: ID!) {
        user(id: $id) {
            id
            userRole
            candidate {
                id
                isUserConnected
            }
            company {
                id
                isConnectionRequested
            }
        }
        checkJobInvitation(id: $id, jobId: $jobId)
        checkJobApplication(id:$id, jobId:$jobId)
    }
`

export const MUTATION_INVITE_CANDIDATE = gql`
    mutation InviteCandidateToConnect($id: ID!, $jobId: ID!) {
        inviteCandidateToConnect(id: $id, jobId: $jobId) {
            id
        }
    }
`

export const GET_USER_NOTIFICATION_DETAIL_QUERY = gql`
    query UserNotificationDetail($data: String!) {
        userNotificationDetail(data: $data)
    }
`